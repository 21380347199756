import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";
import { Ref } from "@/common/decorators/Ref";

@Component
export default class VerticalTextInputFormComponent extends Vue {
  @Prop({ default: null }) value!: string;
  @Prop({ default: null }) label!: string;
  @Prop({ default: null }) prefix!: string;
  @Prop({ default: null }) mask!: string;
  @Prop({ default: false }) showAnnotation!: boolean;
  @Prop({ default: null }) annotation!: string;

  @Prop({ default: null }) icon!: string;
  @Prop({ default: null }) iconTooltip!: string;
  @Prop({ default: null }) iconText!: string;

  @Prop({ default: null }) rules!: string;
  @Prop({ default: false }) modDisabled!: boolean;
  @Prop({ default: false }) firstFocus!: boolean;

  @Prop({ default: "eager" }) validationMode!: string;

  @Ref() input: any;
  @Ref() inputWithPrefix: any;

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit("input", value);
  }

  created() {
    if (this.firstFocus && this.input) {
      this.input.$el.focus();
    }

    if (this.firstFocus && this.inputWithPrefix) {
      this.inputWithPrefix.$el.focus();
    }
  }

  clickIcon(): void {
    this.$emit("onClickIcon");

  }
}

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";
import { Ref } from "@/common/decorators/Ref";

@Component
export default class MessageModalComponent extends Vue {
  @Prop({default: null}) title!: string;
  @Prop({default: null}) message!: string;

  @Ref() dialog!: HTMLDialogElement;

  onConfirm() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  onCloseModal() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  onOpenModal() {
    if (this.dialog) {
      this.dialog.showModal();
    }
  }
}

export abstract class RouteConstants {
  // Common gedeelte
  static readonly RegistratiePage = "/registratie";
  static readonly RegistratieSuccessPage = "/registratie-success";

  // Public gedeelte
  static readonly LandingPage = "/";
  static readonly CallBackPage = "/callback";
  static readonly NotFoundPage = "/not-found";
  static readonly UnauthorizedPage = "/unauthorized";
  static readonly LogoutPage = "/logout";
  static readonly GeenRechtenInformatiePage = "/geen-rechten-informatie";

  // Private gedeelte
  static readonly Private = "/private";
  static readonly StartPage = "/startpagina";
  static readonly BeheerPage = "/beheer";
  static readonly BegunstigdenPage = "/beheer/begunstigden";
  static readonly LeveranciersPage = "/beheer/leveranciers";
  static readonly SoorttypenPage = "/beheer/soorttypen";
  static readonly BeheerLandingspaginaPage = "/beheer/landingspagina";
  static readonly KadastraleToestandPage = "/kadastrale-toestand";
  static readonly LeveringenPage = "/leveringen";
  static readonly ThemabestandPage = "/themabestand";
  static readonly DownloadPage = "/download";
  static readonly DownloadVoorkooprechtenPage = "/download/voorkooprechten";
  static readonly DownloadReferentieDataPage = "/download/referentie-data";
  static readonly PerceelHistoriekPage = "/perceel-historiek";
  static readonly DashboardPage = "/dashboard";
}

export abstract class RouteNameConstants {
  // Public gedeelte
  static readonly LandingPage = "landing";
  static readonly CallBackPage = "callback";
  static readonly NotFoundPage = "not-found";
  static readonly UnauthorizedPage = "unauthorized";
  static readonly LogoutPage = "Logout";
  static readonly GeenRechtenInformatiePage = "geen-rechten-informatie";
  static readonly RegistratiePublicPage = "registratie";
  static readonly RegistratieSuccessPublicPage = "registratie-success";

  // Private gedeelte
  static readonly StartPage = "private-startpagina";
  static readonly BeheerPage = "private-beheer";
  static readonly BeheerBegunstigdenPage = "private-beheer-begunstigden";
  static readonly BeheerSoorttypenPage = "private-beheer-soorttypen";
  static readonly BeheerLeveranciersPage = "private-beheer-leveranciers";
  static readonly SoorttypeToevoegenPage = "private-soorttype-toevoegen";
  static readonly SoorttypeDetailPage = "private-soorttype-detail";
  static readonly BegunstigdeToevoegenPage = "private-begunstigde-toevoegen";
  static readonly BegunstigdeDetailPage = "private-begunstigde-detail";
  static readonly LeverancierToevoegenPage = "private-leverancier-toevoegen";
  static readonly LeverancierDetailPage = "private-leverancier-detail";
  static readonly KadastraleToestandPage = "private-kadastrale-toestand";
  static readonly KadastraleToestandToevoegenPage = "private-kadastrale-toestand-toevoegen";
  static readonly KadastraleToestandDetailPage = "private-kadastrale-toestand-detail";
  static readonly LeveringenPage = "private-leveringen";
  static readonly LeveringToevoegenPage = "private-levering-toevoegen";
  static readonly BeheerLandingspaginaPage = "private-beheer-landingspagina";
  static readonly ThemabestandPage = "private-themabestand";
  static readonly DownloadPage = "private-download";
  static readonly DownloadVoorkooprechtenPage = "private-download-voorkooprechten";
  static readonly DownloadReferentieDataPage = "private-download-referentie-data";
  static readonly DownloadVoorkooprechtenAanvraagPage = "private-voorkooprechten-download-aanvraag-toevoegen";
  static readonly RegistratiePrivatePage = "private-registratie";
  static readonly RegistratieSuccessPrivatePage = "private-registratie-success";
  static readonly PerceelHistoriekPage = "private-perceel-historiek";
  static readonly DashboardPage = "private-dashboard";
}

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";
import { Watch } from "@/common/decorators/Watch";
import { Ref } from "@/common/decorators/Ref";

@Component
export default class VerticalDatepickerFormComponent extends Vue {
  @Prop({ default: null }) vid!: string;
  @Prop({ default: null }) label!: string;
  @Prop({ default: false }) showAnnotation!: boolean;
  @Prop({ default: null }) annotation!: string;
  @Prop({ default: null }) value!: string;
  @Prop({ default: "" }) rules!: string;
  @Prop({ default: "tooltips.datepicker.default" }) tooltipTranslationKey!: string;
  @Prop({ default: false }) firstFocus!: boolean;
  @Prop({ default: false }) modDisabled!: boolean;

  @Ref() input!: any;

  @Watch("firstFocus")
  public(newVal: boolean) {
    if (newVal) {
      this.input.$el.focus();
    }
  }

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit("input", value);
  }

  created() {
    if (this.firstFocus && this.input) {
      this.input.$el.focus();
    }
  }
}

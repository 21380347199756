import axios, { InternalAxiosRequestConfig } from "axios";
import { useCurrentContextStore } from "@/stores/currentContextStore";

export function httpInterceptor() {

  axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    const currentContextStore = useCurrentContextStore();
    currentContextStore.setHasHttpError(false);
    currentContextStore.setIsLoading(true);
    return request;
  });

  axios.interceptors.response.use((response) => {
    const currentContextStore = useCurrentContextStore();
    currentContextStore.setHasHttpError(false);
    currentContextStore.setIsLoading(false);
    return response;
  }, (error) => {
    const currentContextStore = useCurrentContextStore();
    currentContextStore.setIsLoading(false);
    currentContextStore.setHasHttpError(true);

    return Promise.reject(error);
  });
}

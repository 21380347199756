import DateAdapter, { DATE_FORMAT_DEFAULTS } from "./date-adapter";
import { isEmpty } from "@/common/utils/typescript-utilities";

export function startsWith (value: string, { prefix }: { prefix: string }) {
  if(isEmpty(value)) return true;

  return value.toLowerCase().startsWith(prefix.toLowerCase());
}

export function exactLength(value: string, { length }: { length: number }) {
  return value.length == length;
}

export function numericSqlWildcards(value: string){
  if (isEmpty(value)) {
    return true;
  }

  const regex = /^[0-9%_]+$/;
  const testValue = (val: unknown) => {
    const strValue = String(val);

    return regex.test(strValue);
  };

  return testValue(value);
}

export function greaterThanOrEqualSqlMinDate(value: string, format = DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat) {
  if(!value || isEmpty(value)) return true;

  const date = DateAdapter.toDayjsDate(value, format);
  const sqlMinDate = DateAdapter.toDayjsDate("01011753", DATE_FORMAT_DEFAULTS.displayDateOnlyFormat);
  return DateAdapter.isGreaterThanOrEqual(date, sqlMinDate);
}

export function greaterThanGivenValue(value: number, { maxValue }: { maxValue: number }) {
  return +value > +maxValue;
}

import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";
import { Watch } from "@/common/decorators/Watch";
import { isEmpty } from "@/common/utils/typescript-utilities";

export interface VerticalAutocompleteFormComponentData {
  title: string;
  subData: string;
  selectValue: string;
}

@Component
export default class VerticalAutocompleteFormComponent extends Vue {
  @Prop({ default: null }) value!: string;
  @Prop({ default: null }) label!: string;
  @Prop({ default: false }) showAnnotation!: boolean;
  @Prop({ default: null }) annotation!: string;
  @Prop({ default: null }) rules!: string;
  @Prop({ default: () => [] }) data!: VerticalAutocompleteFormComponentData[];
  @Prop({ default: 1 }) filterAfterNumberOfKeystrokes!: number;

  $refs!: { autocomplete: any };
  filterDisabled = false;

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit("input", value);
  }

  @Watch("value")
  public(newValue: string) {
    if(isEmpty(newValue)) {
      this.$emit("select", {title: null, subData: null, selectValue: null});
      return;
    }

    if (this.filterDisabled || (!newValue || newValue && newValue.length < this.filterAfterNumberOfKeystrokes)) {
      return;
    }

    this.$emit("filter", newValue);
  }

  get hasReachedSearchTerm(): boolean {
    return this.value ? this.value.length >= this.filterAfterNumberOfKeystrokes : false;
  }

  get showAutocompleteOptions(): boolean {
    const hasData = this.data.length > 0;
    return hasData && this.hasReachedSearchTerm && !this.filterDisabled;
  }

  activateAutocomplete(): void {
    this.filterDisabled = false;
  }

  select(data: VerticalAutocompleteFormComponentData): void {
    this.filterDisabled = true;
    this.$emit("select", data);
  }

  clear(): void {
    this.$emit("clear");
  }
}

import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";
import Vue from "vue";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VlTooltip } from "@govflanders/vl-ui-vue-components";

@Component({
  directives: {"vl-tooltip": VlTooltip}
})
export default class VerticalTextComponent extends Vue {
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) value!: string;
  @Prop({ default: false }) showIcon!: boolean;
  @Prop() icon!: string;
  @Prop() tooltip!: string;

  onIconClick() {
    this.$emit('click');
  }
}

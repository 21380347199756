import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "@/common/decorators/Ref";
import { Prop } from "@/common/decorators/Prop";

@Component
export default class ConfirmCancelModalComponent extends Vue {
  @Prop({default: null}) title!: string;
  @Prop({default: null}) message!: string;
  @Prop({default: null}) confirmText!: string;
  @Prop({default: null}) cancelText!: string;

  @Ref() dialog!: HTMLDialogElement;

  isOpen(): boolean {
    return this.dialog.open;
  }

  onClickConfirm() {
    this.$emit("confirm");
    this.onCloseModal();
  }

  onClickCancel() {
    this.$emit("cancel");
    this.onCloseModal();
  }

  onClickClose() {
    this.$emit("close");
    this.onCloseModal();
  }

  onCloseModal() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  onOpenModal() {
    if (this.dialog) {
      this.dialog.showModal();
    }
  }
}

import { BehaviorSubject } from 'rxjs';
import { Nullable } from "@/common/utils/typescript-utilities";

export interface ToasterMessage {
  id: number;
  title: string;
  text: string;
  detail: Nullable<string>;
}

const toasterMessagesSubject = new BehaviorSubject([] as Array<ToasterMessage>);
let toasterMessages = [] as Array<ToasterMessage>;

export interface ToasterSuccessMessage {
  title: string;
}

const toasterSuccessMessageSubject = new BehaviorSubject([] as Array<ToasterSuccessMessage>);
let toasterSuccessMessages = [] as Array<ToasterSuccessMessage>;

export const toasterService = {
  addMessage,
  removeMessage,
  messages: toasterMessagesSubject.asObservable(),

  addSuccessMessage,
  removeSuccessMessage,
  successMessages: toasterSuccessMessageSubject.asObservable(),
}

function addMessage(title: string, text: string, detail: Nullable<string> = null) {
  const message = {
    title,
    text,
    detail
  } as ToasterMessage;
  message.id = dateToTicks(new Date());
  toasterMessages.push(message);
  toasterMessagesSubject.next(toasterMessages)
}

function addSuccessMessage(message: ToasterSuccessMessage) {
  toasterSuccessMessages.push(message);
  toasterSuccessMessageSubject.next(toasterSuccessMessages)
}

function removeMessage(message: ToasterMessage) {
  toasterMessages = toasterMessages.filter(({id}) => id !== message.id);
  toasterMessagesSubject.next(toasterMessages);
}

function removeSuccessMessage() {
  toasterSuccessMessages = [];
  toasterSuccessMessageSubject.next(toasterSuccessMessages);
}

function dateToTicks(date: Date) {
  const epochOffset = 621355968000000000;
  const ticksPerMillisecond = 10000;

  const ticks = date.getTime() * ticksPerMillisecond + epochOffset;

  return ticks;
}

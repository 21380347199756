import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "@/common/decorators/Prop";

export interface RadioListItem {
  displayValue: string;
  value: string;
}

@Component
export default class VerticalRadioListFormComponent extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ default: null }) label!: string;
  @Prop({ default: false }) showAnnotation!: boolean;
  @Prop({ default: null }) annotation!: string;
  @Prop({ required: true, default: () => [] }) values!: RadioListItem[];
  @Prop({ default: null }) value!: string;
  @Prop({ default: false }) checked!: string;
  @Prop({ default: null }) rules!: string;
  @Prop({ default: null }) mask!: string;

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit("input", value);
  }
}

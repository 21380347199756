import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "@/common/decorators/Prop";
import i18n from "@/i18n/i18n";

@Component
export default class VerticalDropdownFormComponent extends Vue {
  @Prop() label!: string;
  @Prop() annotation!: string;
  @Prop() value!: string;
  @Prop() rules!: string;
  @Prop() mask!: string;
  @Prop({required: false}) showAnnotation!: boolean;
  @Prop({required: true}) values!: any[];
  @Prop({required: true}) valuePropertyName!: string;
  @Prop({required: true}) displayValuePropertyName!: string;
  @Prop({default: false}) withEmptyValue!: boolean;
  @Prop({default: false}) firstFocus!: boolean;
  @Prop({ default: false }) modDisabled!: boolean;
  
  $refs!: { select: any };

  created() {
    if (this.firstFocus && this.$refs.select) {
      this.$refs.select.$el.focus();
    }

    if (this.withEmptyValue) {
      this.pushEmptyValueToCollection();
    }
  }

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit("input", value);
  }

  private pushEmptyValueToCollection(): void {
    const obj = {} as any;
    obj[this.valuePropertyName] = '';
    obj[this.displayValuePropertyName] = i18n.tc("global.empty-value");

    this.values.unshift(obj); // unshift zorgt ervoor dat obj als eerte in de array komt.
  }
}

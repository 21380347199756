export type Nullable<T> = T | null;

export type Undefinable<T> = T | undefined;

export interface Dictionary<T> {
  [Key: string]: T;
}

export function valueOrUndefined<T>(value: any): T | undefined {
  if (value) {
    return value as T;
  }

  return undefined;
}

export type ValueOf<T> = T[keyof T];

export function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '' || value === 'undefined' || value === 'null') {
    return true;
  }

  return Array.isArray(value) && value.length === 0;
}

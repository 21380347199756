import Vue from "vue";
import Component from "vue-class-component";
import { useCurrentContextStore } from "@/stores/currentContextStore";
import { CurrentUserInfo, securityService } from "@/shared/security/security-service";
import IdleComponent from "@/common/components/idle-component/IdleComponent.vue";

@Component({
  components: { IdleComponent }
})
export default class ThePrivateBase extends Vue {
  useCurrentContextStore = useCurrentContextStore();

  async beforeRouteEnter(to: any, from: any, next: any) {
    const currentContextStore = useCurrentContextStore();

    await currentContextStore.fetchSperperiodeInformatie();

    if (currentContextStore.permissions.length === 0) {
      await currentContextStore.fetchPermissions();
    }

    const currentUserData = await securityService.getCurrentUserInfo() as CurrentUserInfo;

    if (!currentContextStore.getUsername) {
      currentContextStore.setUsername(currentUserData.username);
    }

    if (!currentContextStore.getRole) {
      currentContextStore.setRole(currentUserData.role);
    }

    if (!currentContextStore.getEmail) {
      currentContextStore.setEmail(currentUserData.email);
    }

    if (!currentContextStore.getOrganisatieNaam) {
      currentContextStore.setOrganisatieNaam(currentUserData.organisatieNaam);
    }

    next();
  }
}

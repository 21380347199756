import config from "@/common/config/config";
import axios, { InternalAxiosRequestConfig } from "axios";
import { securityService } from "../security/security-service";

export function jwtInterceptor() {
  axios.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
    const hasBearerTokenInLocalStorage = securityService.hasBearerToken;
    const isApiUrl = request.url?.startsWith(config.api.baseUrl);

    if(hasBearerTokenInLocalStorage && isApiUrl) {
      request.headers = request.headers ?? {};
      request.headers.Authorization = `Bearer ${securityService.bearerToken}`;
    }

    return request;
  });
}

import Vue from "vue";
import local from "./environments/local.json";
import dev from "./environments/dev.json";
import test from "./environments/test.json";
import beta from "./environments/beta.json";
import prod from "./environments/prod.json";
import { EnvironmentConfig } from "@/common/models/environment";

async function fetchConfig(): Promise<string> {
  const environmentResponse = await fetch(process.env.BASE_URL + "environment.json");
  const environmentJson = await environmentResponse.json();
  return environmentJson.environment;
}

function selectConfig(environment: string): EnvironmentConfig {
  switch (environment) {
    case "local":
      return local;
    case "dev":
      return dev;
    case "test":
      return test;
    case "beta":
      return beta;
    case "prod":
      return prod;
    default:
      return local;
  }
}

let config: EnvironmentConfig = local;
if (!Vue.prototype.$environment) {
  Vue.prototype.$environment = await fetchConfig();
}
config = selectConfig(Vue.prototype.$environment);

export default config;

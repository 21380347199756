import Vue from "vue";
import Component from "vue-class-component";
import i18n from "@/i18n/i18n";
import { RouteConstants, RouteNameConstants } from "@/router/router-constants";
import { Watch } from "@/common/decorators/Watch";
import { Prop } from "@/common/decorators/Prop";
import { useCurrentContextStore } from "@/stores/currentContextStore";
import { permissionService } from "@/shared/security/permission-service";
import { ClientRole } from "@/shared/security/role-mapper";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VlTooltip } from "@govflanders/vl-ui-vue-components";
import { Ref } from "@/common/decorators/Ref";

@Component({
  directives: { "vl-tooltip": VlTooltip },
})
export default class NavbarComponent extends Vue {
  @Prop({ required: true }) role!: ClientRole;
  @Prop({ required: true }) username!: string;
  @Prop({ required: true }) organisatieNaam!: string;

  @Ref() popover!: any;

  useCurrentContextStore = useCurrentContextStore();
  title = i18n.t("global.context");
  tabs = this.loadTabs();

  @Watch("$route")
  public() {
    this.tabs = this.loadTabs();
  }

  @Watch("role")
  public roleWatch(newValue: ClientRole) {
    if (newValue) {
      this.tabs = this.loadTabs();
    }
  }

  loadTabs() {
    const tabs: any[] = [];

    if (permissionService.canView(RouteNameConstants.StartPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.startpagina.title"),
        link: RouteConstants.StartPage,
        active: this.$route.path.includes(RouteConstants.StartPage),
      });
    }

    if (permissionService.canView(RouteNameConstants.ThemabestandPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.themabestand.title"),
        link: RouteConstants.ThemabestandPage,
        active: this.$route.path.includes(RouteConstants.ThemabestandPage),
      });
    }

    if (permissionService.canView(RouteNameConstants.BeheerPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.beheer.title"),
        link: RouteConstants.BeheerPage,
        active: this.$route.path.includes(RouteConstants.BeheerPage),
      });
    }

    if (permissionService.canView(RouteNameConstants.KadastraleToestandPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.kadastrale-toestand.title"),
        link: RouteConstants.KadastraleToestandPage,
        active: this.$route.path.includes(RouteConstants.KadastraleToestandPage),
      });
    }

    if (permissionService.canView(RouteNameConstants.LeveringenPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.leveringen.title"),
        link: RouteConstants.LeveringenPage,
        active: this.$route.path.includes(RouteConstants.LeveringenPage),
      });
    }

    if (permissionService.canView(RouteNameConstants.DownloadPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.download.title"),
        link: RouteConstants.DownloadPage,
        active: this.$route.path.includes(RouteConstants.DownloadPage),
      });
    }

    if (permissionService.canView(RouteNameConstants.RegistratiePrivatePage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.registratie.title"),
        link: `${RouteConstants.Private}${RouteConstants.RegistratiePage}`,
        active: this.$route.path.includes(RouteConstants.RegistratiePage),
      });
    }

    if (permissionService.canView(RouteNameConstants.PerceelHistoriekPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.perceel-historiek.title"),
        link: `${RouteConstants.PerceelHistoriekPage}`,
        active: this.$route.path.includes(RouteConstants.PerceelHistoriekPage),
      });
    }

    if (permissionService.canView(RouteNameConstants.DashboardPage, this.role)) {
      tabs.push({
        name: i18n.t("navigation.dashboard.title"),
        link: `${RouteConstants.DashboardPage}`,
        active: this.$route.path.includes(RouteConstants.DashboardPage),
      });
    }

    return tabs;
  }
}

import { defineStore } from "pinia";
import { Nullable } from "@/common/utils/typescript-utilities";
import { ClientRole } from "@/shared/security/role-mapper";
import axios from "axios";
import config from "@/common/config/config";
import { Permission } from "@/common/models/permission-constants";
import { SperperiodeInformatie } from "@/common/models/sperperiode";

interface CurrentContextState {
  username: Nullable<string>;
  organisatieNaam: Nullable<string>;
  email: Nullable<string>;
  role: Nullable<ClientRole>;
  beheer: any;
  download: any;
  isLoading: boolean;
  hasHttpError: boolean;
  permissions: Permission[];
  versie: Nullable<string>;
  sperperiodeInformatie: Nullable<SperperiodeInformatie>
}

const baseUrl = config.api.baseUrl;
const rootUrl = `${baseUrl}/security`;

export const useCurrentContextStore = defineStore("currentContextStore", {
  state: (): CurrentContextState => {
    return {
      username: null,
      organisatieNaam: null,
      email: null,
      role: null,
      beheer: {
        tabIndex: 0,
      },
      download: {
        tabIndex: 0,
      },
      isLoading: false,
      hasHttpError: false,
      permissions: [],
      versie: null,
      sperperiodeInformatie: null
    };
  },
  getters: {
    getUsername: (state) => state.username,
    getOrganisatieNaam: (state) => state.organisatieNaam,
    getEmail: (state) => state.email,
    getRole: (state) => state.role,
    hasRole: (state) => (role: ClientRole) => state.role === role,
    getBeheerTabIndex: (state) => state.beheer.tabIndex,
    getDownloadTabIndex: (state) => state.download.tabIndex,
    hasPermission: (state) => (permissionName: Permission) => state.permissions.includes(permissionName),
    getVersie: (state) => state.versie,
    getHasHttpError: (state) => state.hasHttpError,
    getSperperiodeInformatie: (state) => state.sperperiodeInformatie,
  },
  actions: {
    setIsLoading(value: boolean) {
      this.isLoading = value;
    },
    setHasHttpError(value: boolean) {
      this.hasHttpError = value;
    },
    setBeheerTabIndex(index: number) {
      this.beheer.tabIndex = index;
    },
    setDownloadTabIndex(index: number) {
      this.download.tabIndex = index;
    },
    setUsername(username: Nullable<string>) {
      this.username = username;
    },
    setEmail(email: Nullable<string>) {
      this.email = email;
    },
    setOrganisatieNaam(organisatieNaam: string) {
      this.organisatieNaam = organisatieNaam;
    },
    setRole(role: Nullable<ClientRole>) {
      this.role = role;
    },
    async fetchPermissions() {
      this.hasHttpError = false;

      await axios
        .get(`${rootUrl}/current-user-permissions`)
        .then((data) => {
          this.permissions = data.data.permissions;
        })
        .catch(() => {
          this.hasHttpError = true;
        });
    },
    async fetchVersie() {
      this.hasHttpError = false;

      await axios
        .get(`${baseUrl}/applicatie/versie`)
        .then((data) => {
          this.versie = data.data;
        })
        .catch(() => {
          this.hasHttpError = true;
        });
    },
    async fetchSperperiodeInformatie() {
      this.hasHttpError = false;

      await axios
        .get(`${baseUrl}/kadastrale-toestand/sperperiode-informatie`)
        .then((data) => {
          this.sperperiodeInformatie = data.data.sperperiodeInformatie;
        })
        .catch(() => {
          this.hasHttpError = true;
        });
    }
  },
});

import Vue, { PropOptions } from 'vue'
import { Constructor } from 'vue/types/options'

const reflectMetadataIsSupported =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  typeof Reflect !== 'undefined' && typeof Reflect.getMetadata !== 'undefined'

export function applyMetadata(
  options: PropOptions | Constructor[] | Constructor,
  target: Vue,
  key: string,
) {
  if (reflectMetadataIsSupported) {
    if (
      !Array.isArray(options) &&
      typeof options !== 'function' &&

      // eslint-disable-next-line no-prototype-builtins
      !options.hasOwnProperty('type') &&
      typeof options.type === 'undefined'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const type = Reflect.getMetadata('design:type', target, key)
      if (type !== Object) {
        options.type = type
      }
    }
  }
}

import DateAdapter, { DATE_FORMAT_DEFAULTS } from "@/shared/date-adapter";
import { isEmpty } from "@/common/utils/typescript-utilities";

export function defaultValue(value: any, arg = "-") {
  if (!isEmpty(value)) {
    return value;
  } else {
    return arg;
  }
}

export function prefix(value: any, prefix = "") {
  if (value) {
    return `${prefix}${value}`;
  }
}

export function formatDate(
  value: any,
  inputFormat = DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat,
  outputFormat = DATE_FORMAT_DEFAULTS.displayDateOnlySlashFormat
) {
  if (value) {
    const date = DateAdapter.toDayjsDate(value, inputFormat);
    return date.format(outputFormat);
  }
}

export function formatDateTime(
  value: any,
  inputFormat = DATE_FORMAT_DEFAULTS.dateTimeFormat,
  outputFormat = DATE_FORMAT_DEFAULTS.displayDateTimeSlashFormat
) {
  if (value) {
    const date = DateAdapter.toDayjsDate(value, inputFormat);
    return date.format(outputFormat);
  }
}

export function split(value: string, splitAfter: number, splitChar = "-") {
  const beforeSplitChar = value.substring(0, splitAfter);
  const afterSplitChar = value.substring(splitAfter);

  return `${beforeSplitChar}${splitChar}${afterSplitChar}`;
}

export function truncate(text: string, tagName: string, length = 50, clamp = '...'){
  const node = document.createElement(tagName);
  node.innerHTML = text;

  const content = node.textContent;
  return content && content.length > length ? content.slice(0, length) + clamp : content;
}
